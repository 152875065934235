import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { POST } from 'src/consts/methods'
import {
  CREATE_INVOICE_POSITION,
  CREATE_INVOICE_POSITION_FAIL,
  CREATE_INVOICE_POSITION_SUCCESS,
  CreateInvoicePositionActionType
} from 'src/redux/batchInvoiceCustomerReducer'
import { navigate } from 'src/helpers/navgate'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* CreateInvoicePositionFlow({ payload }: CreateInvoicePositionActionType): Generator<any, void, any> {
  try {
    yield call(apiCall, { endpoint: '/admin/invoice_positions', method: POST, params: { invoice_position: payload } })
    yield put(CREATE_INVOICE_POSITION_SUCCESS())
    navigate(`batch_customer/${payload.batch_invoice_customer_id}/invoice_positions`)
  } catch (error) {
    yield put(CREATE_INVOICE_POSITION_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* CreateInvoicePositionSaga(): Generator<any, void, any> {
  yield takeLatest(CREATE_INVOICE_POSITION, CreateInvoicePositionFlow)
}
