import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { PUT } from 'src/consts/methods'
import {
  UPDATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE_FAIL,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  UpdateEmailTemplateActionType
} from 'src/redux/emailTemplateReducer'
import { navigate } from 'src/helpers/navgate'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* UpdateEmailTemplatesFlow(action: UpdateEmailTemplateActionType): Generator<any, void, any> {
  try {
    const { id, ...other } = action.payload
    yield call(apiCall, { endpoint: `/admin/email_templates/${id}`, method: PUT, params: { email_template: other } })
    yield put(UPDATE_EMAIL_TEMPLATE_SUCCESS())
    navigate('/email_templates')
  } catch (error) {
    yield put(UPDATE_EMAIL_TEMPLATE_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* UpdateEmailTemplatesSaga(): Generator<any, void, any> {
  yield takeLatest(UPDATE_EMAIL_TEMPLATE, UpdateEmailTemplatesFlow)
}
