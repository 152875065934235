import { createReducer, createAction, PayloadAction } from '@reduxjs/toolkit'
import { PersonalizedEmailTemplate, PersonalizedEmailTemplateType } from '../types/index'

export interface PersonalizedEmailTemplateReducer {
  templates: Record<string, Record<PersonalizedEmailTemplateType, PersonalizedEmailTemplate>>
}

const initialState: PersonalizedEmailTemplateReducer = {
  templates: {}
}

export interface FetchPersonalizedEmailTemplatePayload {
  order_id: number
  name: PersonalizedEmailTemplateType
}
export type FetchPersonalizedEmailTemplateActionType = PayloadAction<FetchPersonalizedEmailTemplatePayload>
export const FETCH_PERSONALIZED_EMAIL_TEMPLATE = createAction<FetchPersonalizedEmailTemplatePayload>(
  'personalized_email_template_reducer/fetch_personalized_email'
)
export const FETCH_PERSONALIZED_EMAIL_TEMPLATE_SUCCESS = createAction<PersonalizedEmailTemplate>(
  'personalized_email_template_reducer/fetch_personalized_email_success'
)
export const FETCH_PERSONALIZED_EMAIL_TEMPLATE_FAIL = createAction(
  'personalized_email_template_reducer/fetch_personalized_email_fail'
)

export const personalizedEmailTemplateReducer = createReducer(initialState, (builder) => {
  builder.addCase(FETCH_PERSONALIZED_EMAIL_TEMPLATE_SUCCESS, (state, action) => {
    return {
      ...state,
      templates: {
        ...state.templates,
        [action.payload.order_id]: {
          ...state.templates[action.payload.order_id],
          [action.payload.name]: action.payload
        }
      }
    }
  })
})

export default personalizedEmailTemplateReducer
