/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect } from 'react'
import Layout from '../Layout/Layout'
import { Form, Field } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { FETCH_ORDER } from 'src/redux/orderReducer'
import { useSearchParams } from 'react-router-dom'
import { RootReducer } from 'src/redux/rootReducer'
import { CREATE_INVOICE, CreateInvoicePayload } from 'src/redux/invoiceReducer'

const NewInvoice = (): JSX.Element => {
  const [searchParams] = useSearchParams()
  const orderId = parseInt(searchParams.get('order_id') ?? '', 10)
  const orderData = useSelector((state: RootReducer) => state.orderReducer)
  const isOrderLoaded = orderData.order_number.length > 0
  const isLoading = useSelector((state: RootReducer) => state.invoiceReducer.isLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(FETCH_ORDER({ id: orderId }))
  }, [dispatch, orderId])

  if (!isOrderLoaded) {
    return <></>
  }

  const initialFormValues: CreateInvoicePayload = {
    price_gross: orderData.price,
    zip_from: orderData.from_address?.zip ?? '',
    zip_to: orderData.to_address?.zip ?? '',
    mark: orderData.mark,
    bike_model: orderData.bike_model,
    company_name: orderData.invoice_address?.name ?? '',
    street: orderData.invoice_address?.street ?? '',
    street_number: orderData.invoice_address?.street_number ?? '',
    zip: orderData.invoice_address?.zip ?? '',
    city: orderData.invoice_address?.city ?? '',
    description: `Transport: ${orderData.mark} ${orderData.bike_model}`,
    order_id: orderId,
    without_tax: false,
    order_number: orderData.order_number,
    payment_method: '',
    transferred_on: ''
  }

  const required = (value: string | undefined): string | undefined => {
    // eslint-disable-next-line no-undefined, @typescript-eslint/strict-boolean-expressions
    return value ? undefined : 'Required'
  }

  return (
    <Layout>
      <div className="font-semibold text-22 p-3">
        <Form
          initialValues={initialFormValues}
          onSubmit={(values: CreateInvoicePayload) => {
            dispatch(CREATE_INVOICE(values))
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div>Kunde ausgewählt</div>
              <div className="h-3" />
              <div>Datum der Auftragserteilung</div>
              <div className="h-3" />
              <div className="flex flex-wrap max-w-[40rem] gap-5">
                <div>
                  <label className="text-16 block">Leistungsdatum</label>
                  <Field
                    name="execution_date"
                    className="border border-lightGray text-16 px-2 py-1 text-darkGrey"
                    type="date"
                    component="input"
                  />
                </div>
                <div>
                  <label className="text-16 block">Price gross</label>
                  <Field
                    validate={required}
                    name="price_gross"
                    className="border border-lightGray text-16 px-2 py-1 text-darkGrey"
                    component="input"
                  />
                </div>
                <div>
                  <label className="text-16 block">Zip from</label>
                  <Field
                    validate={required}
                    name="zip_from"
                    className="border border-lightGray text-16 px-2 py-1 text-darkGrey"
                    component="input"
                  />
                </div>
                <div>
                  <label className="text-16 block">Zip to</label>
                  <Field
                    validate={required}
                    name="zip_to"
                    className="border border-lightGray text-16 px-2 py-1 text-darkGrey"
                    component="input"
                  />
                </div>
                <div>
                  <label className="text-16 block">Mark</label>
                  <Field
                    validate={required}
                    name="mark"
                    className="border border-lightGray text-16 px-2 py-1 text-darkGrey"
                    component="input"
                  />
                </div>
                <div>
                  <label className="text-16 block">Bike model</label>
                  <Field
                    validate={required}
                    name="bike_model"
                    className="border border-lightGray text-16 px-2 py-1 text-darkGrey"
                    component="input"
                  />
                </div>
                <div>
                  <label className="text-16 block">Tax number</label>
                  <Field
                    name="tax_number"
                    className="border border-lightGray text-16 px-2 py-1 text-darkGrey"
                    component="input"
                  />
                </div>
                <div>
                  <label className="text-16 block">Payment method</label>
                  <label htmlFor="cash" className="text-16 block">
                    <Field
                      validate={required}
                      name="payment_method"
                      component="input"
                      className="mr-1"
                      id="cash"
                      type="radio"
                      value="cash"
                    />
                    Bar erhalten
                  </label>

                  <label htmlFor="transfer" className="text-16 block">
                    <Field
                      validate={required}
                      name="payment_method"
                      component="input"
                      className="mr-1"
                      id="transfer"
                      type="radio"
                      value="transfer"
                    />
                    überwiesen am
                  </label>
                  <label htmlFor="transfer_instant" className="text-16 block">
                    <Field
                      validate={required}
                      name="payment_method"
                      component="input"
                      className="mr-1"
                      id="transfer_instant"
                      type="radio"
                      value="transfer_instant"
                    />
                    Sofort zahlbar per Überweisung
                  </label>
                </div>

                <div>
                  <label className="text-16 block">überwiesen am date</label>
                  <Field
                    name="transferred_on"
                    className="border border-lightGray text-16 px-2 py-1 text-darkGrey"
                    component="input"
                    type="date"
                  />
                </div>
                <div>
                  <label className="text-16 block">Without tax</label>
                  <Field
                    name="without_tax"
                    className="border border-lightGray text-16 px-2 py-1 text-darkGrey"
                    component="input"
                    type="checkbox"
                  />
                </div>
              </div>
              <div className="h-3" />
              <div className="h-3" />
              <div>Kundendaten</div>
              <div className="h-3" />
              <div className="flex flex-wrap max-w-[40rem] gap-5">
                <div>
                  <label className="text-16 block">Company name</label>
                  <Field
                    validate={required}
                    name="company_name"
                    className="border border-lightGray text-16 px-2 w-[20rem] py-1 text-darkGrey"
                    component="input"
                  />
                </div>
                <div className="w-20" />
                <div>
                  <label className="text-16 block">Street</label>
                  <Field
                    validate={required}
                    name="street"
                    className="border border-lightGray text-16 px-2 w-[17rem] py-1 text-darkGrey"
                    component="input"
                  />
                </div>
                <div>
                  <label className="text-16 block">Street number</label>
                  <Field
                    validate={required}
                    name="street_number"
                    className="border border-lightGray text-16 px-2 w-[17rem] py-1"
                    component="input"
                  />
                </div>
                <div>
                  <label className="text-16 block">Zip</label>
                  <Field
                    validate={required}
                    name="zip"
                    className="border border-lightGray text-16 px-2 w-[17rem] py-1 text-darkGrey"
                    component="input"
                  />
                </div>
                <div>
                  <label className="text-16 block">City</label>
                  <Field
                    validate={required}
                    name="city"
                    className="border border-lightGray text-16 px-2 w-[17rem] py-1 text-darkGrey"
                    component="input"
                  />
                </div>
                <div className="w-[35.5rem]">
                  <label className="text-16 block">Description</label>
                  <Field
                    validate={required}
                    name="description"
                    className="border border-lightGray text-16 px-2 w-full text-darkGrey"
                    component="textarea"
                  />
                </div>
              </div>
              <button
                disabled={isLoading}
                type="submit"
                className="bg-orange text-14 py-2 px-10 text-center rounded-md cursor-pointer disabled:cursor-not-allowed">
                Create
              </button>
            </form>
          )}
        />
      </div>
    </Layout>
  )
}

export default NewInvoice
