import React from 'react'

interface Props {
  active: boolean
  label: string | JSX.Element
  onClick?: () => void
}

const PaginationButton = ({ active, label, onClick }: Props): JSX.Element => {
  return (
    <div
      onClick={onClick}
      className="w-[55px] h-[40px] bg-[#f4f4f4] text-[18px] flex justify-between items-center flex-col group cursor-pointer rounded-sm overflow-hidden">
      <div
        className={`flex justify-center items-center flex-1 ${
          active ? 'text-black' : 'text-[#666]'
        } group-hover:text-orange`}>
        {label}
      </div>
      <div
        className={`${active ? 'h-1' : 'h-0'} w-full bg-orange group-hover:h-1 transition-all transition-duration-[1s]`}
      />
    </div>
  )
}

export default PaginationButton
