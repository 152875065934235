import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { PUT } from 'src/consts/methods'
import {
  FETCH_BATCH_INVOICE_CUSTOMER_FAIL,
  FETCH_BATCH_INVOICE_CUSTOMER_SUCCESS,
  UPDATE_BATCH_INVOICE_CUSTOMER,
  UpdateBatchInvoiceCustomerActionType
} from 'src/redux/batchInvoiceCustomerReducer'
import { navigate } from 'src/helpers/navgate'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* UpdateBatchInvoiceCustomerFlow({ payload }: UpdateBatchInvoiceCustomerActionType): Generator<any, void, any> {
  try {
    const { id, ...params } = payload
    const response = yield call(apiCall, {
      endpoint: `/admin/batch_invoice_customers/${payload.id}`,
      method: PUT,
      params: { batch_invoice_customer: params }
    })
    yield put(FETCH_BATCH_INVOICE_CUSTOMER_SUCCESS(response))
    navigate('/batch_customer')
  } catch (error) {
    yield put(FETCH_BATCH_INVOICE_CUSTOMER_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* UpdateBatchInvoiceCustomerSaga(): Generator<any, void, any> {
  yield takeLatest(UPDATE_BATCH_INVOICE_CUSTOMER, UpdateBatchInvoiceCustomerFlow)
}
