import React from 'react'
import Layout from '../Layout/Layout'

const OrdersScreen = (): JSX.Element => {
  return (
    <Layout withFilter>
      <div className="flex">x</div>
    </Layout>
  )
}

export default OrdersScreen
