/* eslint-disable @typescript-eslint/ban-types */
import { createReducer, createAction } from '@reduxjs/toolkit'
import { EmailTemplate } from '../types/index'

export interface EmailTemplatesReducer {
  email_templates: EmailTemplate[]
}

const initialState: EmailTemplatesReducer = {
  email_templates: []
}

export const FETCH_EMAIL_TEMPLATES = createAction('email_templates_reducer/fetch_email_templates')
export const FETCH_EMAIL_TEMPLATES_SUCCESS = createAction<EmailTemplate[]>(
  'email_templates_reducer/fetch_email_templates_success'
)
export const FETCH_EMAIL_TEMPLATES_FAIL = createAction('email_templates_reducer/fetch_email_templates_fail')

export const emailTemplatesReducer = createReducer(initialState, (builder) => {
  builder.addCase(FETCH_EMAIL_TEMPLATES_SUCCESS, (state, action) => {
    return { ...state, email_templates: action.payload }
  })
})

export default emailTemplatesReducer
