import React from 'react'
import Layout from '../Layout/Layout'

const EditUserScreen = (): JSX.Element => {
  return (
    // TO_DO implement this function
    <Layout withFilter>
      <div className="font-semibold text-22 p-3">
        <div>Ändere das Passwort vom name last name</div>
        <div className="h-3" />
        <label className="text-16 block">E-Mail-Addresse</label>
        <input className="border text-16 px-1" />
        <div className="h-3" />
        <label className="text-16 block">Das neue Passwort</label>
        <input className="border text-16 px-1" />
        <div className="h-3" />
        <label className="text-16 block">Bestätige das Passwort</label>
        <input className="border text-16 px-1" />
        <div className="h-3" />
        <label className="text-16 block">Das aktuelle Passwort</label>
        <input className="border text-16 px-1" />
        <div className="h-3" />
        <button className="bg-orange text-14 py-2 px-10 text-center rounded-md cursor-pointer">Update</button>
      </div>
    </Layout>
  )
}

export default EditUserScreen
