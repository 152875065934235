import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { setNavigate } from './navgate'

const NavigateProvider = (): JSX.Element => {
  const navigate = useNavigate()
  useEffect(() => {
    setNavigate(navigate)
  }, [navigate])
  return <></>
}

export default NavigateProvider
