import { configureStore } from '@reduxjs/toolkit'
import rootReducer from '../redux/rootReducer'
// import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { rootSaga } from '../sagas'

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware)
})

sagaMiddleware.run(rootSaga)

export default store
