/* eslint-disable */
import { rootUrl } from '../consts/other'
import getCognitoAccessToken from './getCognitoAccessToken'

export type ApiCallParams<T> = {
  endpoint: string
  method: string
  params: T
}

const formatParams = (params: any): string => {
  const getPairs = (params: any, keys: string[] = []): [string[], any][] => {
    return Object.entries(params).reduce((pairs: [string[], any][], [key, value]) => {
      if (typeof value === 'object') {
        pairs.push(...getPairs(value, [...keys, key]))
      } else {
        pairs.push([[...keys, key], value])
      }
      return pairs
    }, [] as [string[], any][])
  }

  return (
    '?' +
    getPairs(params)
      .map(([[key0, ...keysRest], value]) => `${key0}${keysRest.map((a) => `[${a}]`).join('')}=${value}`)
      .join('&')
  )
}

const apiCall = ({ endpoint, params, method }: ApiCallParams<any>): Promise<any> => {
  let fullUrl = rootUrl + endpoint
  if (method === 'GET' && params) {
    fullUrl += formatParams(params)
  }
  const json = JSON.stringify(params)
  const headers = new Headers()
  headers.append('Content-type', 'application/json')

  return new Promise((resolve, reject) => {
    getCognitoAccessToken()
      .then((token) => {
        if (token) {
          headers.append('Authorization', token)
        }

        fetch(fullUrl, {
          method,
          headers,
          body: method !== 'GET' ? json : undefined
        })
          .then((response) => {
            if (response.ok) {
              return response.json()
            }
            throw new Error('Network response was not ok.')
          })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
      .catch((error) => {
        fetch(fullUrl, {
          method,
          headers,
          body: method !== 'GET' ? json : undefined
        })
          .then((response) => {
            if (response.ok) {
              return response.json()
            }
            throw new Error('Network response was not ok.')
          })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
  })
}

export default apiCall
