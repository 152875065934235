import React from 'react'
import './App.css'
import store from './store/store'
import { Provider } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import SignIn from './components/organisms/SignIn'
import OrdersScreen from './components/organisms/OrdersScreen'
import EditUserScreen from './components/organisms/EditUserScreen'
import UsersListScreen from './components/organisms/UsersListScreen'
import NewUserScreen from './components/organisms/NewUserScreen'
import EmailTemplatesScreen from './components/organisms/EmailTemplatesScreen'
import EditEmailTemplateScreen from './components/organisms/EditEmailTemplateScreen'
import NavigateProvider from './helpers/NavigateProvider'
import BatchCustomerScreen from './components/organisms/BatchCustomerScreen'
import BatchCustomerForm from './components/organisms/BatchCustomerForm'
import NewInvoicePosition from './components/organisms/NewInvoicePosition'
import InvoicePositionsScreen from './components/organisms/InvoicePositionsScreen'
import InvoicesScreen from './components/organisms/InvoicesScreen'
import InvoiceScreen from './components/organisms/InvoiceScreen'
import EmailModal from './components/molecules/EmailModal'
import NewInvoice from './components/organisms/NewInvoice'

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <NavigateProvider />
        <EmailModal />
        <>
          <Routes>
            <Route path="/users/sign_in" Component={SignIn} />
            <Route path="/orders" Component={OrdersScreen} />
            <Route path="/users/edit" Component={EditUserScreen} />
            <Route path="/users" Component={UsersListScreen} />
            <Route path="/users/sign_up" Component={NewUserScreen} />
            <Route path="/email_templates" Component={EmailTemplatesScreen} />
            <Route path="/email_templates/:id/edit" Component={EditEmailTemplateScreen} />
            <Route path="/batch_customer" Component={BatchCustomerScreen} />
            <Route path="/batch_customer/new" Component={() => <BatchCustomerForm type="new" />} />
            <Route path="/batch_customer/:id/edit" Component={() => <BatchCustomerForm type="edit" />} />
            <Route path="/invoice_positions/new" Component={NewInvoicePosition} />
            <Route
              path="/batch_customer/:batchInvoiceCustomerId/invoice_positions"
              Component={InvoicePositionsScreen}
            />
            <Route path="/invoices" Component={InvoicesScreen} />
            <Route path="/invoices/:id" Component={InvoiceScreen} />
            <Route path="/invoices/new" Component={NewInvoice} />
          </Routes>
        </>
      </BrowserRouter>
    </Provider>
  )
}

export default App
