import { createReducer, createAction, PayloadAction } from '@reduxjs/toolkit'
import { EmailTemplate } from '../types/index'

export interface EmailTemplateReducer extends EmailTemplate {}

const initialState: EmailTemplateReducer = {
  name: '',
  body: '',
  title: '',
  id: 0
}

interface FetchEmailTemplatePayload {
  id: number
}
export type FetchEmailTemplateActionType = PayloadAction<FetchEmailTemplatePayload>
export const FETCH_EMAIL_TEMPLATE = createAction<FetchEmailTemplatePayload>('email_template_reducer/fetch_email')
export const FETCH_EMAIL_TEMPLATE_SUCCESS = createAction<EmailTemplate>('email_template_reducer/fetch_email_success')
export const FETCH_EMAIL_TEMPLATE_FAIL = createAction('email_template_reducer/fetch_email_fail')

interface UpdateEmailTemplatePayload {
  body: string
  title: string
  id: number
}
export type UpdateEmailTemplateActionType = PayloadAction<UpdateEmailTemplatePayload>
export const UPDATE_EMAIL_TEMPLATE = createAction<UpdateEmailTemplatePayload>(
  'email_template_reducer/update_email_template'
)
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = createAction('email_template_reducer/update_email_template_success')
export const UPDATE_EMAIL_TEMPLATE_FAIL = createAction('email_template_reducer/update_email_template_fail')

export const emailTemplateReducer = createReducer(initialState, (builder) => {
  builder.addCase(FETCH_EMAIL_TEMPLATE_SUCCESS, (state, action) => {
    return { ...state, ...action.payload }
  })
})

export default emailTemplateReducer
