import React, { useEffect } from 'react'
import Layout from '../Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { DELETE_USER, FETCH_USERS } from 'src/redux/usersReducer'
import { RootReducer } from 'src/redux/rootReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'

const UsersListScreen = (): JSX.Element => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(FETCH_USERS())
  }, [dispatch])
  const users = useSelector((state: RootReducer) => state.usersReducer.users)

  const handleDeleteUser = (id: number): void => {
    const confirmation = window.confirm('Sicher den Arbeiter löschen?')
    if (confirmation) {
      dispatch(DELETE_USER({ id }))
    }
  }

  return (
    <Layout withFilter>
      <div className="p-3">
        <h1 className="text-24 ml-6">Mitarbeiter</h1>
        <div className="h-3" />
        <div className="bg-white rounded-sm w-full overflow-hidden">
          <div className="border-b-4 border-orange" />
          <div className="p-3">
            <table className="w-full text-14">
              <tr>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Name</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Nachname</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">E-Mail-Addresse</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Die Rolle des Mitarbeiters</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold"></td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold"></td>
              </tr>
              {users.map(({ id, first_name, last_name, email, user_role }, idx) => (
                <tr key={id} className={`${idx % 2 === 0 ? 'bg-grayBackground' : 'bg-white'}`}>
                  <td className="p-2 border border-[#f4f4f4]">{first_name}</td>
                  <td className="p-2 border border-[#f4f4f4]">{last_name}</td>
                  <td className="p-2 border border-[#f4f4f4]">{email}</td>
                  <td className="p-2 border border-[#f4f4f4]">{user_role}</td>
                  <td className="p-2 border border-[#f4f4f4]">
                    <a href={`users/${id}/edit`} className="flex-1 justify-center flex items-center cursor-pointer">
                      <FontAwesomeIcon icon={faEdit} />
                    </a>
                  </td>
                  <td className="p-2 border border-[#f4f4f4]">
                    <div
                      onClick={() => {
                        handleDeleteUser(id)
                      }}
                      className="flex-1 justify-center flex items-center cursor-pointer">
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
        <div className="h-4" />
        <a className="text-14 text-blue" href="/users/sign_up">
          Der neue Nutzer
        </a>
        <p className="text-14">
          Danach kannst du es hier bearbeiten, den Namen und die Role ändern. Am Anfang ist immer die Rolle {'"'}User
          {'"'} also mit dem einfachsten Zugang.
        </p>
      </div>
    </Layout>
  )
}

export default UsersListScreen
