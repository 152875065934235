import React, { useEffect, useState } from 'react'
import Layout from '../Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FETCH_EMAIL_TEMPLATE, UPDATE_EMAIL_TEMPLATE } from 'src/redux/emailTemplateReducer'
import { RootReducer } from 'src/redux/rootReducer'

const EditEmailTemplateScreen = (): JSX.Element => {
  const reducerBody = useSelector((state: RootReducer) => state.emailTemplateReducer.body)
  const reducerTitle = useSelector((state: RootReducer) => state.emailTemplateReducer.title)
  const [body, setBody] = useState('')
  const [title, setTitle] = useState('')
  const dispatch = useDispatch()
  const { id } = useParams()
  const intId = parseInt(id ?? '', 10)
  useEffect(() => {
    dispatch(FETCH_EMAIL_TEMPLATE({ id: intId }))
  }, [intId, dispatch])

  useEffect(() => {
    setBody(reducerBody)
    setTitle(reducerTitle)
  }, [reducerBody, reducerTitle])

  return (
    <Layout withFilter>
      <div className="p-3">
        <h1 className="text-24">Vorlage</h1>
        <label className="block text-18 font-semibold">Titel</label>
        <input
          value={title}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
          className="border w-full border-lightGray px-2 py-1 rounded-sm text-depGraphite cursor-pointer"
        />
        <div className="h-4" />
        <label className="block text-18 font-semibold">Inhalt</label>
        <textarea
          onChange={(e) => {
            setBody(e.target.value)
          }}
          value={body}
          rows={10}
          className="border w-full border-lightGray px-2 py-1 rounded-sm text-depGraphite cursor-pointer"
        />
        <div className="h-4" />
        <button
          onClick={() => {
            dispatch(UPDATE_EMAIL_TEMPLATE({ body, title, id: intId }))
          }}
          className="bg-orange text-14 py-2 px-10 text-center rounded-md cursor-pointer text-white">
          Endgültig speichern
        </button>
      </div>
    </Layout>
  )
}

export default EditEmailTemplateScreen
