/* eslint-disable @typescript-eslint/ban-types */
import { createReducer, createAction, PayloadAction } from '@reduxjs/toolkit'
import { Order } from 'src/types'

export interface OrderReducer extends Order {}

const initialState: OrderReducer = {
  order_number: '',
  bike_model: '',
  price: '',
  from_address: null,
  to_address: null,
  invoice_address: null,
  mark: ''
}

interface FetchOrderPayload {
  id: number
}
export type FetchOrderActiontype = PayloadAction<FetchOrderPayload>
export const FETCH_ORDER = createAction<FetchOrderPayload>('order_reducer/fetch_order')
export const FETCH_ORDER_SUCCESS = createAction<Order>('order_reducer/fetch_order_success')
export const FETCH_ORDER_FAIL = createAction('order_reducer/fetch_order_fail')

export const orderReducer = createReducer(initialState, (builder) => {
  builder.addCase(FETCH_ORDER_SUCCESS, (state, action) => {
    return { ...state, ...action.payload }
  })
})
export default orderReducer
