import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { DELETE } from 'src/consts/methods'
import { DELETE_USER, DELETE_USER_FAIL, DELETE_USER_SUCCESS, DeleteUserAction } from 'src/redux/usersReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* DeleteUserFlow(action: DeleteUserAction): Generator<any, void, any> {
  try {
    yield call(apiCall, { endpoint: `/admin/users/${action.payload.id}`, method: DELETE, params: {} })
    yield put(DELETE_USER_SUCCESS({ id: action.payload.id }))
  } catch (error) {
    yield put(DELETE_USER_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* DeleteUserSaga(): Generator<any, void, any> {
  yield takeLatest(DELETE_USER, DeleteUserFlow)
}
