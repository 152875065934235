/* eslint-disable @typescript-eslint/ban-types */
import { createReducer, createAction, PayloadAction } from '@reduxjs/toolkit'
import { ListInvoice } from '../types/index'

type SortOrder = 'asc' | 'desc'

export type SortType =
  | { invoice_number: SortOrder }
  | { zip_form: SortOrder }
  | { zip_to: SortOrder }
  | { created_at: SortOrder }

export interface InvoicesReducer {
  invoices: ListInvoice[]
  total_pages: number
  total_count: number
  currentPage: number
  sort: SortType
  search: SearchPayload
}

const initialState: InvoicesReducer = {
  invoices: [],
  total_pages: 0,
  total_count: 0,
  currentPage: 1,
  sort: { created_at: 'desc' },
  search: { order_number: '', invoice_number: '', date_min: '', date_max: '' }
}

export const FETCH_INVOICES = createAction('invoices_reducer/fetch_invoices')
interface FetchInvoiceSuccessPayload {
  total_pages: number
  total_count: number
  invoices: ListInvoice[]
}
export const FETCH_INVOICES_SUCCESS = createAction<FetchInvoiceSuccessPayload>(
  'invoices_reducer/fetch_invoices_success'
)
export const FETCH_INVOICES_FAIL = createAction('invoices_reducer/fetch_invoices_fail')

export const NEXT_PAGE = createAction('invoices_reducer/next_page')
export const PREVIOUS_PAGE = createAction('invoices_reducer/previous_page')
interface SetPagePayload {
  page: number
}
export const SET_PAGE = createAction<SetPagePayload>('invoices_reducer/set_page')
export const SET_SORT = createAction<SortType>('invoices_reducer/set_sort')

interface SearchPayload {
  order_number: string
  invoice_number: string
  date_min: string
  date_max: string
}
export const SET_SEARCH = createAction<SearchPayload>('invoices_reducer/set_search')

export interface GeneratePdfReportPayload {
  report_form: string
  report_to: string
}
export type GeneratePdfReportActionType = PayloadAction<GeneratePdfReportPayload>
export const GENERATE_PDF_REPORT = createAction<GeneratePdfReportPayload>('invoices_reducer/generate_pdf_report')
export const GENERATE_PDF_REPORT_SUCCESS = createAction('invoices_reducer/generate_pdf_report_success')
export const GENERATE_PDF_REPORT_FAIL = createAction('invoices_reducer/generate_pdf_report_fail')

export interface GenerateCsvReportPayload {
  report_form: string
  report_to: string
}
export type GenerateCsvReportActionType = PayloadAction<GenerateCsvReportPayload>
export const GENERATE_CSV_REPORT = createAction<GenerateCsvReportPayload>('invoices_reducer/generate_csv_report')
export const GENERATE_CSV_REPORT_SUCCESS = createAction('invoices_reducer/generate_csv_report_success')
export const GENERATE_CSV_REPORT_FAIL = createAction('invoices_reducer/generate_csv_report_fail')

export const invoicesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(FETCH_INVOICES_SUCCESS, (state, action) => {
      return { ...state, ...action.payload }
    })
    .addCase(NEXT_PAGE, (state, action) => {
      if (state.currentPage >= state.total_pages) {
        return state
      }
      return { ...state, currentPage: state.currentPage + 1 }
    })
    .addCase(PREVIOUS_PAGE, (state, action) => {
      if (state.currentPage === 1) {
        return state
      }
      return { ...state, currentPage: state.currentPage - 1 }
    })
    .addCase(SET_PAGE, (state, action) => {
      return { ...state, currentPage: action.payload.page }
    })
    .addCase(SET_SORT, (state, action) => {
      return { ...state, sort: action.payload }
    })
    .addCase(SET_SEARCH, (state, action) => {
      return { ...state, search: action.payload }
    })
})

export default invoicesReducer
