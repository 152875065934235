import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { GET } from 'src/consts/methods'
import { FETCH_USERS, FETCH_USERS_FAIL, FETCH_USERS_SUCCESS } from 'src/redux/usersReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* FetchUsersFlow(): Generator<any, void, any> {
  try {
    const response = yield call(apiCall, { endpoint: '/admin/users', method: GET, params: {} })
    yield put(FETCH_USERS_SUCCESS(response))
  } catch (error) {
    yield put(FETCH_USERS_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* FetchUsersSaga(): Generator<any, void, any> {
  yield takeLatest(FETCH_USERS, FetchUsersFlow)
}
