import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { POST } from 'src/consts/methods'
import {
  GENERATE_PDF_REPORT,
  GENERATE_PDF_REPORT_FAIL,
  GENERATE_PDF_REPORT_SUCCESS,
  GeneratePdfReportActionType
} from 'src/redux/invoicesReducer'

interface PdfDocument {
  invoice_number: string
  invoice_pdf: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* CreateInvoiceReportFlow(action: GeneratePdfReportActionType): Generator<any, void, any> {
  try {
    const pdfsDocuments: PdfDocument[] = yield call(apiCall, {
      endpoint: '/admin/invoices/pdf_report',
      method: POST,
      params: { report_form: action.payload.report_form, report_to: action.payload.report_to }
    })
    for (let i = 0; i < pdfsDocuments.length; i += 1) {
      const pdfDocument = pdfsDocuments[i]
      yield downloadPDF(pdfDocument)
    }
    yield put(GENERATE_PDF_REPORT_SUCCESS())
  } catch (error) {
    yield put(GENERATE_PDF_REPORT_FAIL())
  }
}

async function downloadPDF(pdfDocument: PdfDocument): Promise<void> {
  const response = await fetch(pdfDocument.invoice_pdf)
  if (response.ok) {
    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', pdfDocument.invoice_number)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* CreateInvoiceReportSaga(): Generator<any, void, any> {
  yield takeLatest(GENERATE_PDF_REPORT, CreateInvoiceReportFlow)
}
