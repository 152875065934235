/* eslint-disable @typescript-eslint/no-explicit-any */
import userPool from './userPool'
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'

const getCognitoAccessToken = async (): Promise<string> => {
  const cognitoUser: CognitoUser | null = userPool.getCurrentUser()
  if (typeof cognitoUser !== 'object') {
    return ''
  }

  if (cognitoUser === null) {
    return ''
  }

  try {
    const session: CognitoUserSession | null = await new Promise<CognitoUserSession>((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cognitoUser.getSession((err: any, s: CognitoUserSession) => {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (err) {
          reject(err)
        } else {
          resolve(s)
        }
      })
    })

    if (session.isValid()) {
      return session.getAccessToken().getJwtToken() ?? ''
    }
    const refreshedSession = await new Promise<CognitoUserSession>((resolve, reject) => {
      cognitoUser.refreshSession(session.getRefreshToken(), (err, rs) => {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (err) {
          reject(err)
        } else {
          resolve(rs)
        }
      })
    })
    return refreshedSession.getAccessToken().getJwtToken() ?? ''
  } catch (error) {
    return ''
  }
}

export default getCognitoAccessToken
