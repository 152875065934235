import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { OPEN_MODAL } from 'src/redux/emailModalReducer'
import { RootReducer } from 'src/redux/rootReducer'

const InvoiceActions = (): JSX.Element => {
  const { id } = useParams()
  const invoice = useSelector((state: RootReducer) => state.invoiceReducer.invoices[id ?? ''])

  const dispatch = useDispatch()

  if (typeof invoice !== 'object') {
    return <></>
  }

  return (
    <div className="flex justify-start gap-2">
      <a href={invoice.invoice_pdf} className="bg-white border-1 border-darkGrey p-4 text-darkGrey rounded-md">
        download
      </a>
      <button
        onClick={() => {
          dispatch(OPEN_MODAL({ modalType: 'send_invoice', orderId: invoice.order_id }))
        }}
        className="bg-white border-1 border-darkGrey p-4 text-darkGrey rounded-md">
        Send email
      </button>
    </div>
  )
}

export default InvoiceActions
