import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { POST } from 'src/consts/methods'
import { CREATE_USER, CREATE_USER_FAIL, CREATE_USER_SUCCESS, CreateUserActionType } from 'src/redux/usersReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* CreateUserFlow({ payload }: CreateUserActionType): Generator<any, void, any> {
  try {
    yield call(apiCall, { endpoint: '/admin/users', method: POST, params: { user: payload } })
    yield put(CREATE_USER_SUCCESS())
  } catch (error) {
    yield put(CREATE_USER_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* CreateUserSaga(): Generator<any, void, any> {
  yield takeLatest(CREATE_USER, CreateUserFlow)
}
