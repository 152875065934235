import { put, takeLatest } from 'redux-saga/effects'
import { POST } from 'src/consts/methods'
import {
  GENERATE_CSV_REPORT,
  GENERATE_CSV_REPORT_FAIL,
  GENERATE_CSV_REPORT_SUCCESS,
  GenerateCsvReportActionType
} from 'src/redux/invoicesReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* GenereCSVInvoiceReportFlow(action: GenerateCsvReportActionType): Generator<any, void, any> {
  try {
    const response = yield fetch('/admin/invoices/csv_report', {
      method: POST,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ report_form: action.payload.report_form, report_to: action.payload.report_to })
    })
    const blob = yield response.blob()
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'report.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    yield put(GENERATE_CSV_REPORT_SUCCESS())
  } catch (error) {
    yield put(GENERATE_CSV_REPORT_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* GenereCSVInvoiceReportSaga(): Generator<any, void, any> {
  yield takeLatest(GENERATE_CSV_REPORT, GenereCSVInvoiceReportFlow)
}
