import React, { useEffect } from 'react'
import NavigationItem from './NavigationItem'
import { faEnvelope, faFolder, faUser } from '@fortawesome/free-regular-svg-icons'
import {
  faArchive,
  faBan,
  faCircle,
  faCoffee,
  faFileInvoiceDollar,
  faMoneyBill,
  faMotorcycle,
  faPerson,
  faTruck
} from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { FETCH_USER } from 'src/redux/userReducer'
import { RootReducer } from 'src/redux/rootReducer'

interface Props {
  isOpen: boolean
}

const NavBar = ({ isOpen }: Props): JSX.Element => {
  const userRole = useSelector((state: RootReducer) => state.userReducer.user_role)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(FETCH_USER())
  }, [dispatch])

  const functionClasses = isOpen ? 'w-[14.3rem]' : 'w-[0rem]'
  return (
    <div className={`${functionClasses} min-h-[100vh] overflow-hidden`}>
      <div className="bg-darkBlue py-2 px-1">
        <a href="/orders" className="flex items-center justify-center cursor-pointer">
          {isOpen ? (
            <img src="/admin-public/assets/images/logo-spedimoto.png" className="flex-1" />
          ) : (
            <img src="/admin-public/assets/images/logo-spedimoto-small.png" className="w-[86%]" />
          )}
        </a>
      </div>
      {isOpen && (
        <>
          <div className="h-3" />
          <div className="bg-depGraphite px-2 text-white font-normal text-16">Verwalten</div>
          <div className="h-3" />
        </>
      )}
      <div>
        <NavigationItem
          rootLabel="Auftrag"
          rootIcon={faFolder}
          nestedItems={[
            { label: 'Alles', icon: faFolder, href: '/orders' },
            { label: 'Anfrage', icon: faCircle, href: '/orders?filter_statuses%5B%5D=created' },
            { label: 'Link versendet', icon: faCircle, href: '/orders?filter_statuses%5B%5D=link_sent' },
            { label: 'Angebot angenommen', icon: faCircle, href: '/orders?filter_statuses%5B%5D=form_submitted' },
            { label: 'Beauftragt', icon: faMotorcycle, href: '/orders?filter_statuses%5B%5D=processed' },
            { label: 'in Arbeit +', icon: faCoffee, href: '/orders?filter_statuses%5B%5D=partially_finished' },
            { label: 'Beendet', icon: faMoneyBill, href: '/orders?filter_statuses%5B%5D=finished' },
            { label: 'In Transport', icon: faCoffee, href: '/orders?filter_statuses%5B%5D=in_transport' },
            { label: 'Archiwiert', icon: faArchive, href: '/orders?filter_statuses%5B%5D=archived' }
          ]}
        />
        {userRole === 'super_admin' && (
          <>
            <NavigationItem
              rootLabel="Routen"
              rootIcon={faFolder}
              nestedItems={[
                { label: 'Routen', icon: faTruck, href: '/routes' },
                { label: 'Gelöscht', icon: faBan, href: '/routes/archived' }
              ]}
            />
            <NavigationItem
              rootLabel="Rechnungen"
              rootIcon={faFolder}
              nestedItems={[
                { label: 'Rechnungen', icon: faFileInvoiceDollar, href: '/invoices' },
                { label: 'Sammelrechnung Kunde', icon: faPerson, href: '/batch_customer' }
              ]}
            />
            <NavigationItem rootLabel="Vorlagen" rootIcon={faEnvelope} rootHref="/email_templates" />
            <NavigationItem rootLabel="Mitarbeiter" rootIcon={faUser} rootHref="/users" />
          </>
        )}
      </div>
    </div>
  )
}

export default NavBar
