import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootReducer } from 'src/redux/rootReducer'

const Invoice = (): JSX.Element => {
  const { id } = useParams()
  const invoice = useSelector((state: RootReducer) => state.invoiceReducer.invoices[id ?? ''])

  if (typeof invoice !== 'object') {
    return <></>
  }

  return (
    <div className="max-w-[1000px]">
      <div className="h-2" />
      <h2 className="uppercase text-[36px] leading-[80%]">Rechnung</h2>
      <p>Rechnung an {invoice.invoice_number}</p>
      <div className="h-20" />
      <div className="flex justify-between">
        <div>
          <p className="text-18 uppercase font-semibold">Rechnungsempfänger</p>
          <div className="h-1" />
          <p className="leading-[110%]">{invoice.display_name}</p>
          <p className="leading-[110%]">
            {invoice.street} {invoice.street_number}
          </p>
          <p className="leading-[110%]">
            {invoice.zip} {invoice.city}
          </p>
        </div>
        <div>
          <p className="text-18 uppercase font-semibold">Rechnungssteller</p>
          <div className="h-1" />
          <p className="leading-[110%]">Moto-Top</p>
          <p className="leading-[110%]">Florian Gutwinski</p>
          <p className="leading-[110%]">Weinbergstr. 13a</p>
          <p className="leading-[110%]">38350 Helmstedt</p>
        </div>
      </div>
      <div className="h-20" />
      <div className="flex justify-between">
        <div className="w-[150px]">
          <p className="text-18 uppercase font-semibold">Rechnung</p>
          <div className="h-2" />
          <div className="border-b border-black w-full border-2 border-orange" />
          <div className="h-3" />
          <p className="text-18 font-semibold">Auftragsnummer</p>
          {invoice.invoice_positions.map((item) => (
            <p key={item.id} className="leading-[110%]">
              {item.order_number}
            </p>
          ))}
          <div className="h-3" />
          <p className="text-18 font-semibold">Rechnungsnummer</p>
          <p className="leading-[110%]">{invoice.invoice_number}</p>
          <div className="h-3" />
          <p className="text-18 font-semibold">Rechnungsnummer</p>
          <p className="leading-[110%]">Leistungsdatum</p>
          <div className="h-3" />
          <p className="text-18 font-semibold">Erstellungsdatum</p>
          <p className="leading-[110%]">{invoice.created_at}</p>
        </div>
        <div className="w-16" />
        <div className="flex-1">
          <div>
            <div className="flex">
              <div className="flex-1 flex justify-start items-start">
                <p className="text-18 font-semibold">Beschreibung</p>
              </div>
              <div className="w-[100px] flex justify-center items-start">
                <p className="text-18 font-semibold">von PLZ</p>
              </div>
              <div className="w-[100px] flex justify-center items-start">
                <p className="text-18] font-semibold">nach PLZ</p>
              </div>
              <div className="w-[100px] flex justify-center items-start">
                <div className="text-18 font-semibold">Preis Netto</div>
              </div>
            </div>
            <div className="h-2" />
            <div className="border-b border-black w-full border-2 border-orange" />
          </div>
          {invoice.invoice_positions.map((item, idx) => (
            <div key={item.id}>
              <div className="h-5" />
              <div className="flex">
                <div className="flex-1 flex justify-start items-start">
                  <p className="text-18 font-semibold">
                    {idx + 1}. {item.description}
                  </p>
                </div>
                <div className="w-[100px] flex justify-center items-start">
                  <p className="text-18">{item.zip_from}</p>
                </div>
                <div className="w-[100px] flex justify-center items-start">
                  <p className="text-18]">{item.zip_to}</p>
                </div>
                <div className="w-[100px] flex justify-center items-start">
                  <div className="text-18">{item.price_net.toFixed(2)} €</div>
                </div>
              </div>
              <div className="h-5" />
              <div className="border-b border-black w-full border-1 border-orange" />
            </div>
          ))}
          <div id="invoice-summary" className="flex justify-end">
            <div className="w-[300px]">
              {invoice.tax_value > 0 && (
                <>
                  <div>
                    <div className="h-3" />
                    <div className="flex justify-between px-7">
                      <p className="text-18 uppercase font-semibold">Betrag Netto</p>
                      <p className="text-18">{invoice.price_net.toFixed(2)} €</p>
                    </div>
                    <div className="h-3" />
                    <div className="border-b border-black w-full border-1 border-orange" />
                  </div>
                  <div>
                    <div className="h-3" />
                    <div className="flex justify-between px-7">
                      <p className="text-18 uppercase font-semibold">MwSt. 19%</p>
                      <p className="text-18">{invoice.tax_value.toFixed(2)} €</p>
                    </div>
                    <div className="h-3" />
                    <div className="border-b border-black w-full border-1 border-orange" />
                  </div>
                </>
              )}
              <div className="bg-orange">
                <div className="h-3" />
                <div className="flex justify-between px-7">
                  <p className="text-18 uppercase font-semibold text-white">Gesamtbetrag</p>
                  <p className="text-18 text-white">{invoice.price_gross.toFixed(2)} €</p>
                </div>
                <div className="h-3" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-20" />
      <div className="flex justify-end">
        <p className="text-16 uppercase font-semibold">FLORIAN GUTWINSKI</p>
      </div>
      <div className="h-20" />
      <div className="text-22">
        {invoice.payment_method === 'cash' && 'bar erhalten'} IBAN : DE29 2505 0000 0151 7289 12
      </div>
      <div className="border-b border-black w-full border-1 border-orange" />
      <div className="h-4" />
      <div className="flex justify-evenly">
        <div>
          <p className="leading-[110%]">Rechnungssteller</p>
          <p className="leading-[110%]">Moto-Top</p>
          <p className="leading-[110%]">Florian Gutwinski</p>
          <p className="leading-[110%]">Weinbergstr. 13a</p>
          <p className="leading-[110%]">38350 Helmstedt</p>
        </div>
        <div>
          <p className="leading-[110%]">transport@moto-top.de</p>
          <p className="leading-[110%]">tel. +49 17624375978</p>
          <p className="leading-[110%]">05351 3994631</p>
        </div>
        <div>
          <p className="leading-[110%]">USt-IdNr. DE278590662</p>
          <p className="leading-[110%]">St.-Nr. 28/115/06505</p>
        </div>
        <div>
          <p className="leading-[110%]">BIC: NOLADE2HXXX</p>
        </div>
      </div>
    </div>
  )
}

export default Invoice
