import React, { useEffect } from 'react'
import Layout from '../Layout/Layout'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { FETCH_INVOICE } from 'src/redux/invoiceReducer'
import InvoiceActions from '../molecules/InvoiceActions'
import Invoice from '../molecules/Invoice'

const InvoiceScreen = (): JSX.Element => {
  const { id } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(FETCH_INVOICE({ id: Number(id) }))
  }, [id, dispatch])

  return (
    <Layout>
      <div className="bg-[#ecf0f5] px-10">
        <div className="h-2" />
        <InvoiceActions />
        <div className="h-2" />
        <Invoice />
      </div>
    </Layout>
  )
}

export default InvoiceScreen
