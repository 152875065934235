import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { GET } from 'src/consts/methods'
import {
  FETCH_BATCH_INVOICE_CUSTOMERS,
  FETCH_BATCH_INVOICE_CUSTOMERS_FAIL,
  FETCH_BATCH_INVOICE_CUSTOMERS_SUCCESS
} from 'src/redux/batchInvoiceCustomersReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* FetchBatchInvoiceCustomersFlow(): Generator<any, void, any> {
  try {
    const response = yield call(apiCall, { endpoint: '/admin/batch_invoice_customers', method: GET, params: {} })
    yield put(FETCH_BATCH_INVOICE_CUSTOMERS_SUCCESS(response))
  } catch (error) {
    yield put(FETCH_BATCH_INVOICE_CUSTOMERS_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* FetchBatchInvoiceCustomersSaga(): Generator<any, void, any> {
  yield takeLatest(FETCH_BATCH_INVOICE_CUSTOMERS, FetchBatchInvoiceCustomersFlow)
}
