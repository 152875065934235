import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CLOSE_MODAL, SEND_EMAIL } from 'src/redux/emailModalReducer'
import { FETCH_PERSONALIZED_EMAIL_TEMPLATE } from 'src/redux/personalizedEmailTemplateReducer'
import { RootReducer } from 'src/redux/rootReducer'

const EmailModal = (): JSX.Element => {
  const [to, setTo] = useState('')
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const isEmailModalOpen = useSelector((state: RootReducer) => state.emailModalReducer.isModalOpen)
  const orderId = useSelector((state: RootReducer) => state.emailModalReducer.orderId)
  const modalType = useSelector((state: RootReducer) => state.emailModalReducer.modalType)
  const dispatch = useDispatch()
  const template = useSelector((state: RootReducer) => {
    if (typeof state.personalizedEmailTemplateReducer.templates[orderId.toString()] === 'object') {
      return state.personalizedEmailTemplateReducer.templates[orderId.toString()][modalType] ?? null
    }
    return null
  })

  useEffect(() => {
    if (template !== null) {
      setTo(template.to)
      setTitle(template.title)
      setBody(template.body)
    }
  }, [template])

  useEffect(() => {
    if (isEmailModalOpen) {
      dispatch(FETCH_PERSONALIZED_EMAIL_TEMPLATE({ order_id: orderId, name: modalType }))
    }
  }, [isEmailModalOpen, dispatch, modalType, orderId])

  if (!isEmailModalOpen) {
    return <></>
  }

  const handleCloseEmailModal = (): void => {
    dispatch(CLOSE_MODAL())
  }

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-[rgba(0,0,0,0.3)]">
      <div className="h-4" />
      <div className="max-w-[600px] mx-auto bg-white p-4 rounded-lg">
        <div className="flex justify-between">
          <div>Send Invoice</div>
          <div onClick={handleCloseEmailModal} className="text-lightGray hover:text-[black] cursor-pointer">
            <FontAwesomeIcon className="text-red-[red]" icon={faClose} />
          </div>
        </div>
        <div className="h-2" />
        <div className="border-b-2 border-lightGray" />
        <div className="h-2" />
        <div className="flex justify-between items-center">
          <div className="text-[black] font-semibold">Recipient</div>
          <div className="w-[450px]">
            <input
              value={to}
              onChange={(e) => {
                setTo(e.target.value)
              }}
              className="border-2 border-lightGray rounded-md p-2 w-full"
              type="text"
            />
          </div>
        </div>
        <div className="h-2" />
        <div className="flex justify-between items-center">
          <div className="text-[black] font-semibold">Title</div>
          <div className="w-[450px]">
            <input
              value={title}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
              className="border-2 border-lightGray rounded-md p-2 w-full"
              type="text"
            />
          </div>
        </div>
        <div className="h-2" />
        <div className="flex justify-between items-center">
          <div className="text-[black] font-semibold">Title</div>
          <div className="w-[450px]">
            <textarea
              rows={10}
              value={body}
              onChange={(e) => {
                setBody(e.target.value)
              }}
              className="border-2 border-lightGray rounded-md p-2 w-full"
            />
          </div>
        </div>
        {modalType === 'send_invoice' && (
          <div className="flex justify-between items-center">
            <div className="text-[black] font-semibold">Attachment</div>
            <div className="w-[450px]">
              <p>Invoice.pdf</p>
            </div>
          </div>
        )}
        <div className="h-2" />
        <div className="flex justify-between">
          <button
            onClick={handleCloseEmailModal}
            type="submit"
            className="border-2 border-orange text-14 py-2 px-10 text-center rounded-md cursor-pointer">
            Schließen
          </button>
          <button
            type="submit"
            onClick={() => {
              dispatch(SEND_EMAIL({ to, title, body, orderId, name: modalType }))
            }}
            className="bg-orange text-14 py-2 px-10 text-center rounded-md cursor-pointer text-white">
            Erstellen
          </button>
        </div>
      </div>
    </div>
  )
}

export default EmailModal
