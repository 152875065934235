import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from '../helpers/apiCall'
import { POST } from '../consts/methods'
import {
  CLOSE_MODAL,
  SEND_EMAIL,
  SEND_EMAIL_FAILURE,
  SEND_EMAIL_SUCCESS,
  SendEmailActionType
} from 'src/redux/emailModalReducer'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* SendEmailFlow({ payload }: SendEmailActionType): Generator<any, void, any> {
  try {
    yield call(apiCall, {
      endpoint: '/admin/emails',
      method: POST,
      params: {
        email: {
          recipient: payload.to,
          title: payload.title,
          body: payload.body,
          order_id: payload.orderId,
          name: payload.name
        }
      }
    })
    yield put(SEND_EMAIL_SUCCESS())
    yield put(CLOSE_MODAL())
  } catch (error) {
    yield put(SEND_EMAIL_FAILURE())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* SendEmailSaga(): Generator<any, void, any> {
  yield takeLatest(SEND_EMAIL, SendEmailFlow)
}
