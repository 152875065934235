import { combineReducers, Reducer } from 'redux'
import authReducer, { AuthReducer } from './authReducer'
import { UserReducer, userReducer } from './userReducer'
import { UsersReducer, usersReducer } from './usersReducer'
import { EmailTemplatesReducer, emailTemplatesReducer } from './emailTemplatesReducer'
import { EmailTemplateReducer, emailTemplateReducer } from './emailTemplateReducer'
import { BatchInvoiceCustomersReducer, batchInvoiceCustomersReducer } from './batchInvoiceCustomersReducer'
import { BatchInvoiceCustomerReducer, batchInvoiceCustomerReducer } from './batchInvoiceCustomerReducer'
import { OrderReducer, orderReducer } from './orderReducer'
import { InvoicesReducer, invoicesReducer } from './invoicesReducer'
import { InvoiceReducer, invoiceReducer } from './invoiceReducer'
import { EmailModalReducer, emailModalReducer } from './emailModalReducer'
import { PersonalizedEmailTemplateReducer, personalizedEmailTemplateReducer } from './personalizedEmailTemplateReducer'
export interface RootReducer {
  authReducer: AuthReducer
  userReducer: UserReducer
  usersReducer: UsersReducer
  emailTemplatesReducer: EmailTemplatesReducer
  emailTemplateReducer: EmailTemplateReducer
  batchInvoiceCustomersReducer: BatchInvoiceCustomersReducer
  batchInvoiceCustomerReducer: BatchInvoiceCustomerReducer
  orderReducer: OrderReducer
  invoicesReducer: InvoicesReducer
  invoiceReducer: InvoiceReducer
  emailModalReducer: EmailModalReducer
  personalizedEmailTemplateReducer: PersonalizedEmailTemplateReducer
}
const rootReducer: Reducer<RootReducer> = combineReducers({
  authReducer,
  userReducer,
  usersReducer,
  emailTemplatesReducer,
  emailTemplateReducer,
  batchInvoiceCustomersReducer,
  batchInvoiceCustomerReducer,
  orderReducer,
  invoicesReducer,
  invoiceReducer,
  emailModalReducer,
  personalizedEmailTemplateReducer
})

export default rootReducer
