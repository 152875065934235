import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { GET } from 'src/consts/methods'
import { FETCH_ORDER, FETCH_ORDER_FAIL, FETCH_ORDER_SUCCESS, FetchOrderActiontype } from 'src/redux/orderReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* FetchOrderFlow({ payload }: FetchOrderActiontype): Generator<any, void, any> {
  try {
    const response = yield call(apiCall, {
      endpoint: `/admin/orders/${payload.id}`,
      method: GET,
      params: {}
    })
    yield put(FETCH_ORDER_SUCCESS(response))
  } catch (error) {
    yield put(FETCH_ORDER_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* FetchOrderSaga(): Generator<any, void, any> {
  yield takeLatest(FETCH_ORDER, FetchOrderFlow)
}
