import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { POST } from 'src/consts/methods'
import { navigate } from 'src/helpers/navgate'
import {
  CREATE_INVOICE,
  CREATE_INVOICE_FAIL,
  CREATE_INVOICE_SUCCESS,
  CreateInvoiceActionType
} from 'src/redux/invoiceReducer'
import { Invoice } from 'src/types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* CreateInvoiceFlow({ payload }: CreateInvoiceActionType): Generator<any, void, any> {
  try {
    const response: Invoice = yield call(apiCall, {
      endpoint: '/admin/invoices',
      method: POST,
      params: { invoice: payload }
    })
    yield put(CREATE_INVOICE_SUCCESS(response))
    navigate(`invoices/${String(response.id)}`)
  } catch (error) {
    yield put(CREATE_INVOICE_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* CreateInvoiceSaga(): Generator<any, void, any> {
  yield takeLatest(CREATE_INVOICE, CreateInvoiceFlow)
}
