import { all } from 'redux-saga/effects'
import SignInSaga from './SignInSaga'
import FetchUserSaga from './FetchUserSaga'
import SignOutSaga from './SignOutSaga'
import FetchUsersSaga from './FetchUsersSaga'
import DeleteUserSaga from './DeleteUserSaga'
import CreateUserSaga from './CreateUserSaga'
import FetchEmailTemplatesSaga from './FetchEmailTemplatesSaga'
import FetchEmailTemplateSaga from './FetchEmailTemplateSaga'
import UpdateEmailTemplatesSaga from './UpdateEmailTemplatesSaga'
import FetchBatchInvoiceCustomersSaga from './FetchBatchInvoiceCustomersSaga'
import CreateBatchInvoiceCustomerSaga from './CreateBatchInvoiceCustomerSaga'
import FetchBatchInvoiceCustomerSaga from './FetchBatchInvoiceCustomerSaga'
import UpdateBatchInvoiceCustomerSaga from './UpdateBatchInvoiceCustomerSaga'
import FetchOrderSaga from './FetchOrderSaga'
import CreateInvoicePositionSaga from './CreateInvoicePositionSaga'
import FetchInvoicePositionsSaga from './FetchInvoicePositionsSaga'
import CreateInvoiceFromInvoicePositionsSaga from './CreateInvoiceFromInvoicePositionsSaga'
import FetchInvoicesSaga from './FetchInvoicesSaga'
import CreateInvoiceReportSaga from './CreateInvoiceReportSaga'
import GenereCSVInvoiceReportSaga from './GenereCSVInvoiceReportSaga'
import FetchInvoiceSaga from './FetchInvoiceSaga'
import FetchPersonalizedEmailTemplateSaga from './FetchPersonalizedEmailTemplateSaga'
import SendEmailSaga from './SendEmailSaga'
import CreateInvoiceSaga from './CreateInvoiceSaga'
export function* rootSaga(): Generator {
  yield all([
    SignInSaga(),
    FetchUserSaga(),
    SignOutSaga(),
    FetchUsersSaga(),
    DeleteUserSaga(),
    CreateUserSaga(),
    FetchEmailTemplatesSaga(),
    FetchEmailTemplateSaga(),
    UpdateEmailTemplatesSaga(),
    FetchBatchInvoiceCustomersSaga(),
    CreateBatchInvoiceCustomerSaga(),
    FetchBatchInvoiceCustomerSaga(),
    UpdateBatchInvoiceCustomerSaga(),
    FetchOrderSaga(),
    CreateInvoicePositionSaga(),
    FetchInvoicePositionsSaga(),
    CreateInvoiceFromInvoicePositionsSaga(),
    FetchInvoicesSaga(),
    CreateInvoiceReportSaga(),
    GenereCSVInvoiceReportSaga(),
    FetchInvoiceSaga(),
    FetchPersonalizedEmailTemplateSaga(),
    SendEmailSaga(),
    CreateInvoiceSaga()
  ])
}
