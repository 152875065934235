import React, { useEffect } from 'react'
import Layout from '../Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { FETCH_EMAIL_TEMPLATES } from 'src/redux/emailTemplatesReducer'
import { RootReducer } from 'src/redux/rootReducer'
import { useNavigate } from 'react-router-dom'

const EmailTemplatesScreen = (): JSX.Element => {
  const dispatch = useDispatch()
  const emailTemplates = useSelector((state: RootReducer) => state.emailTemplatesReducer.email_templates)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(FETCH_EMAIL_TEMPLATES())
  }, [dispatch])

  return (
    <Layout withFilter>
      <div className="p-3">
        <h1 className="text-24 ml-6">Vorlagenliste</h1>
        <div className="h-3" />
        <div className="bg-white rounded-sm w-full overflow-hidden">
          <div className="border-b-4 border-orange" />
          <div className="p-3">
            <table className="w-full text-14">
              <tr>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Name</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Titel</td>
              </tr>
              {emailTemplates.map(({ name, id, title }, idx) => (
                <tr
                  onClick={() => {
                    navigate(`/email_templates/${id}/edit`)
                  }}
                  key={id}
                  className={`${idx % 2 === 0 ? 'bg-grayBackground' : 'bg-white'} cursor-pointer`}>
                  <td className="p-2 border border-[#f4f4f4]">{name}</td>
                  <td className="p-2 border border-[#f4f4f4]">{title}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EmailTemplatesScreen
