/* eslint-disable @typescript-eslint/ban-types */
import { createReducer, createAction, PayloadAction } from '@reduxjs/toolkit'
import { User } from '../types/index'

export interface UsersReducer {
  users: User[]
}

const initialState: UsersReducer = {
  users: []
}

export const FETCH_USERS = createAction('users_reducer/fetch_users')
export const FETCH_USERS_SUCCESS = createAction<User[]>('users_reducer/fetch_users_success')
export const FETCH_USERS_FAIL = createAction('users_reducer/fetch_users_fail')

interface DeleteUserPayload {
  id: number
}
export type DeleteUserAction = PayloadAction<DeleteUserPayload>
export const DELETE_USER = createAction<DeleteUserPayload>('users_reducers/delete_user')
export const DELETE_USER_SUCCESS = createAction<DeleteUserPayload>('users_reducers/delete_user_success')
export const DELETE_USER_FAIL = createAction('users_reducers/delete_user_fail')

interface CreateUserActionPayload {
  password: string
  email: string
  user_role: User['user_role']
}
export type CreateUserActionType = PayloadAction<CreateUserActionPayload>
export const CREATE_USER = createAction<CreateUserActionPayload>('users_reducer/create_user')
export const CREATE_USER_FAIL = createAction('users_reducer/create_user_fail')
export const CREATE_USER_SUCCESS = createAction('users_reducer/create_user_fail')

export const usersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(FETCH_USERS_SUCCESS, (state, action) => {
      return { ...state, users: action.payload }
    })
    .addCase(DELETE_USER_SUCCESS, (state, action) => {
      return { ...state, users: [...state.users.filter((item) => item.id !== action.payload.id)] }
    })
})

export default usersReducer
