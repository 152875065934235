import React, { useState } from 'react'
import Layout from '../Layout/Layout'
import { useDispatch } from 'react-redux'
import { CREATE_USER } from 'src/redux/usersReducer'
import { User } from 'src/types'

const NewUserScreen = (): JSX.Element => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [role, setRole] = useState<User['user_role']>('user')
  const dispatch = useDispatch()

  const handleButtonClick = (): void => {
    if (password !== passwordConfirmation || password.length === 0) {
      return
    }
    dispatch(CREATE_USER({ password, email, user_role: role }))
  }

  return (
    <Layout withFilter>
      <div className="font-semibold text-22 p-3">
        <div>Create User</div>
        <div className="h-3" />
        <label className="text-16 block">E-Mail-Addresse</label>
        <input
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          className="border text-16 px-1"
        />
        <div className="h-3" />
        <label className="text-16 block">Das neue Passwort</label>
        <input
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value)
          }}
          className="border text-16 px-1"
        />
        <div className="h-3" />
        <label className="text-16 block">Bestätige das Passwort</label>
        <input
          value={passwordConfirmation}
          onChange={(e) => {
            setPasswordConfirmation(e.target.value)
          }}
          className="border text-16 px-1"
        />
        <div className="h-3" />
        <label className="text-16 block">Role</label>
        <select
          value={role}
          onChange={(e) => {
            setRole(e.target.value as User['user_role'])
          }}
          className="border text-16 px-1 w-[11.2rem]">
          <option value="super_admin">super_admin</option>
          <option value="admin">admin</option>
          <option value="user">user</option>
        </select>
        <div className="h-3" />
        <div className="h-3" />
        <button
          onClick={handleButtonClick}
          className="bg-orange text-14 py-2 px-10 text-center rounded-md cursor-pointer">
          Create
        </button>
      </div>
    </Layout>
  )
}

export default NewUserScreen
