import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { GET } from 'src/consts/methods'
import {
  FETCH_EMAIL_TEMPLATES,
  FETCH_EMAIL_TEMPLATES_SUCCESS,
  FETCH_EMAIL_TEMPLATES_FAIL
} from 'src/redux/emailTemplatesReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* FetchEmailTemplatesFlow(): Generator<any, void, any> {
  try {
    const response = yield call(apiCall, { endpoint: '/admin/email_templates', method: GET, params: {} })
    yield put(FETCH_EMAIL_TEMPLATES_SUCCESS(response))
  } catch (error) {
    yield put(FETCH_EMAIL_TEMPLATES_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* FetchEmailTemplatesSaga(): Generator<any, void, any> {
  yield takeLatest(FETCH_EMAIL_TEMPLATES, FetchEmailTemplatesFlow)
}
