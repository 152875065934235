/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect } from 'react'
import Layout from '../Layout/Layout'
import { Form, Field } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { FETCH_ORDER } from 'src/redux/orderReducer'
import { useSearchParams } from 'react-router-dom'
import { RootReducer } from 'src/redux/rootReducer'
import { CREATE_INVOICE_POSITION, CreateInvoicePositionPayload } from 'src/redux/batchInvoiceCustomerReducer'
import { FETCH_BATCH_INVOICE_CUSTOMERS } from 'src/redux/batchInvoiceCustomersReducer'

const NewInvoicePosition = (): JSX.Element => {
  const [searchParams] = useSearchParams()
  const orderId = parseInt(searchParams.get('order_id') ?? '', 10)
  const orderData = useSelector((state: RootReducer) => state.orderReducer)
  const batchInvoiceCustomersOptions = useSelector(
    (state: RootReducer) => state.batchInvoiceCustomersReducer.batch_invoice_customers
  )
  const isOrderLoaded = orderData.order_number.length > 0
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(FETCH_BATCH_INVOICE_CUSTOMERS())
  }, [dispatch])

  useEffect(() => {
    dispatch(FETCH_ORDER({ id: orderId }))
  }, [dispatch, orderId])

  if (!isOrderLoaded || batchInvoiceCustomersOptions.length === 0) {
    return <></>
  }

  const initialFormValues: CreateInvoicePositionPayload = {
    batch_invoice_customer_id: '',
    price_gross: orderData.price,
    zip_from: orderData.from_address?.zip ?? '',
    zip_to: orderData.to_address?.zip ?? '',
    mark: orderData.mark,
    bike_model: orderData.bike_model,
    company_name: orderData.invoice_address?.name ?? '',
    street: orderData.invoice_address?.street ?? '',
    street_number: orderData.invoice_address?.street_number ?? '',
    zip: orderData.invoice_address?.zip ?? '',
    city: orderData.invoice_address?.city ?? '',
    description: `Transport: ${orderData.mark} ${orderData.bike_model}`,
    order_id: orderId,
    order_number: orderData.order_number
  }

  const required = (value: string | undefined): string | undefined => {
    // eslint-disable-next-line no-undefined, @typescript-eslint/strict-boolean-expressions
    return value ? undefined : 'Required'
  }

  return (
    <Layout>
      <div className="font-semibold text-22 p-3">
        <Form
          initialValues={initialFormValues}
          onSubmit={(values: CreateInvoicePositionPayload) => {
            dispatch(CREATE_INVOICE_POSITION(values))
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div>Kunde ausgewählt</div>
              <div className="h-3" />
              <Field
                name="batch_invoice_customer_id"
                validate={required}
                render={({ input }) => (
                  <select {...input} className="border border-lightGray text-16 px-2 py-1 text-darkGrey w-40">
                    <option></option>
                    {batchInvoiceCustomersOptions.map(({ id, company_name }) => (
                      <option value={id} key={id}>
                        {company_name}
                      </option>
                    ))}
                  </select>
                )}
              />
              <div className="h-3" />
              <div>Datum der Auftragserteilung</div>
              <div className="h-3" />
              <div className="flex flex-wrap max-w-[40rem] gap-5">
                <div>
                  <label className="text-16 block">Price gross</label>
                  <Field
                    validate={required}
                    name="price_gross"
                    className="border border-lightGray text-16 px-2 py-1 text-darkGrey"
                    component="input"
                  />
                </div>
                <div>
                  <label className="text-16 block">Zip from</label>
                  <Field
                    validate={required}
                    name="zip_from"
                    className="border border-lightGray text-16 px-2 py-1 text-darkGrey"
                    component="input"
                  />
                </div>
                <div>
                  <label className="text-16 block">Zip to</label>
                  <Field
                    validate={required}
                    name="zip_to"
                    className="border border-lightGray text-16 px-2 py-1 text-darkGrey"
                    component="input"
                  />
                </div>
                <div>
                  <label className="text-16 block">Mark</label>
                  <Field
                    validate={required}
                    name="mark"
                    className="border border-lightGray text-16 px-2 py-1 text-darkGrey"
                    component="input"
                  />
                </div>
                <div>
                  <label className="text-16 block">Bike model</label>
                  <Field
                    validate={required}
                    name="bike_model"
                    className="border border-lightGray text-16 px-2 py-1 text-darkGrey"
                    component="input"
                  />
                </div>
              </div>
              <div className="h-3" />
              <div className="h-3" />
              <div>Kundendaten</div>
              <div className="h-3" />
              <div className="flex flex-wrap max-w-[40rem] gap-5">
                <div>
                  <label className="text-16 block">Company name</label>
                  <Field
                    validate={required}
                    name="company_name"
                    className="border border-lightGray text-16 px-2 w-[20rem] py-1 text-darkGrey"
                    component="input"
                  />
                </div>
                <div className="w-20" />
                <div>
                  <label className="text-16 block">Street</label>
                  <Field
                    validate={required}
                    name="street"
                    className="border border-lightGray text-16 px-2 w-[17rem] py-1 text-darkGrey"
                    component="input"
                  />
                </div>
                <div>
                  <label className="text-16 block">Street number</label>
                  <Field
                    validate={required}
                    name="street_number"
                    className="border border-lightGray text-16 px-2 w-[17rem] py-1"
                    component="input"
                  />
                </div>
                <div>
                  <label className="text-16 block">Zip</label>
                  <Field
                    validate={required}
                    name="zip"
                    className="border border-lightGray text-16 px-2 w-[17rem] py-1 text-darkGrey"
                    component="input"
                  />
                </div>
                <div>
                  <label className="text-16 block">City</label>
                  <Field
                    validate={required}
                    name="city"
                    className="border border-lightGray text-16 px-2 w-[17rem] py-1 text-darkGrey"
                    component="input"
                  />
                </div>
                <div className="w-[35.5rem]">
                  <label className="text-16 block">Description</label>
                  <Field
                    validate={required}
                    name="description"
                    className="border border-lightGray text-16 px-2 w-full text-darkGrey"
                    component="textarea"
                  />
                </div>
              </div>
              <button type="submit" className="bg-orange text-14 py-2 px-10 text-center rounded-md cursor-pointer">
                Create
              </button>
            </form>
          )}
        />
      </div>
    </Layout>
  )
}

export default NewInvoicePosition
