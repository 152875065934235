import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from '../helpers/apiCall'
import { POST } from '../consts/methods'
import {
  CREATE_BATCH_INVOICE_CUSTOMER,
  CREATE_BATCH_INVOICE_CUSTOMER_FAIL,
  CREATE_BATCH_INVOICE_CUSTOMER_SUCCESS,
  CreateBatchInvoiceCustomerActionType
} from '../redux/batchInvoiceCustomersReducer'
import { navigate } from 'src/helpers/navgate'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* CreateBatchInvoiceCustomerFlow({ payload }: CreateBatchInvoiceCustomerActionType): Generator<any, void, any> {
  try {
    yield call(apiCall, {
      endpoint: '/admin/batch_invoice_customers',
      method: POST,
      params: { batch_invoice_customer: payload }
    })
    yield put(CREATE_BATCH_INVOICE_CUSTOMER_SUCCESS())
    navigate('/batch_customer')
  } catch (error) {
    yield put(CREATE_BATCH_INVOICE_CUSTOMER_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* CreateBatchInvoiceCustomerSaga(): Generator<any, void, any> {
  yield takeLatest(CREATE_BATCH_INVOICE_CUSTOMER, CreateBatchInvoiceCustomerFlow)
}
