import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { GET } from 'src/consts/methods'
import {
  FETCH_PERSONALIZED_EMAIL_TEMPLATE,
  FETCH_PERSONALIZED_EMAIL_TEMPLATE_FAIL,
  FETCH_PERSONALIZED_EMAIL_TEMPLATE_SUCCESS,
  FetchPersonalizedEmailTemplateActionType
} from 'src/redux/personalizedEmailTemplateReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* FetchPersonalizedEmailTemplateFlow(
  action: FetchPersonalizedEmailTemplateActionType
): Generator<any, void, any> {
  try {
    const response = yield call(apiCall, {
      endpoint: '/admin/personalized_email_templates',
      method: GET,
      params: { template_name: action.payload.name, order_id: action.payload.order_id }
    })
    yield put(FETCH_PERSONALIZED_EMAIL_TEMPLATE_SUCCESS(response))
  } catch (error) {
    yield put(FETCH_PERSONALIZED_EMAIL_TEMPLATE_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* FetchPersonalizedEmailTemplateSaga(): Generator<any, void, any> {
  yield takeLatest(FETCH_PERSONALIZED_EMAIL_TEMPLATE, FetchPersonalizedEmailTemplateFlow)
}
