import React from 'react'
import PaginationButton from '../atoms/PaginationButton'
import Arrow from '../icons/ArrowSvg'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducer } from 'src/redux/rootReducer'
import { NEXT_PAGE, PREVIOUS_PAGE } from 'src/redux/invoicesReducer'
import { NavLink, useLocation } from 'react-router-dom'

function createArrayAround(number: number, max: number): number[] {
  const length = 9
  let start = Math.max(3, number - Math.floor(length / 2))
  const end = Math.min(start + length - 1, max)
  start = Math.max(3, end - length + 1)
  const result = []
  // eslint-disable-next-line no-plusplus
  for (let i = start; i <= end; i++) {
    result.push(i)
  }
  return result
}

const Pagination = (): JSX.Element => {
  const dispatch = useDispatch()
  const totalPages = useSelector((state: RootReducer) => state.invoicesReducer.total_pages)
  const currentPage = useSelector((state: RootReducer) => state.invoicesReducer.currentPage)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const getPageUrl = (page: number): string => {
    searchParams.set('page', page.toString())
    return `?${searchParams.toString()}`
  }

  return (
    <>
      <div className="h-3" />
      <div className="flex">
        <PaginationButton
          onClick={() => dispatch(PREVIOUS_PAGE())}
          active={false}
          label={<Arrow style={{ width: '18px', height: '18px' }} fill={'#666'} />}
        />
        <div className="w-2" />
        <NavLink to={getPageUrl(1)}>
          <PaginationButton active={currentPage === 1} label={'1'} />
        </NavLink>
        <div className="w-2" />
        <NavLink to={getPageUrl(2)}>
          <PaginationButton active={currentPage === 2} label={'2'} />
        </NavLink>
        <div className="w-1" />
        <div className="flex items-center justify-center text-22">...</div>
        <div className="w-1" />
        {createArrayAround(currentPage, totalPages - 2).map((page) => (
          <React.Fragment key={page}>
            <NavLink to={getPageUrl(page)}>
              <PaginationButton active={currentPage === page} label={page.toString()} />
            </NavLink>
            <div className="w-2" />
          </React.Fragment>
        ))}
        {totalPages > 11 && (
          <>
            <div className="w-1" />
            <div className="flex items-center justify-center text-22">...</div>
            <div className="w-1" />
            <NavLink to={getPageUrl(totalPages - 1)}>
              <PaginationButton active={currentPage === totalPages - 1} label={(totalPages - 1).toString()} />
            </NavLink>
            <div className="w-2" />
            <NavLink to={getPageUrl(totalPages)}>
              <PaginationButton active={currentPage === totalPages} label={totalPages.toString()} />
            </NavLink>
            <div className="w-2" />
            <PaginationButton
              onClick={() => dispatch(NEXT_PAGE())}
              active={false}
              label={<Arrow style={{ transform: 'rotate(180deg)', width: '18px', height: '18px' }} fill={'#666'} />}
            />
          </>
        )}
      </div>
    </>
  )
}

export default Pagination
