/* eslint-disable @typescript-eslint/ban-types */
import { createReducer, createAction, PayloadAction } from '@reduxjs/toolkit'
import { BatchInvoiceCustomer } from '../types/index'

export interface BatchInvoiceCustomersReducer {
  batch_invoice_customers: BatchInvoiceCustomer[]
}

const initialState: BatchInvoiceCustomersReducer = {
  batch_invoice_customers: []
}

export const FETCH_BATCH_INVOICE_CUSTOMERS = createAction(
  'batch_invoice_customers_reducers/fetch_batch_invoice_customers'
)
export const FETCH_BATCH_INVOICE_CUSTOMERS_SUCCESS = createAction<BatchInvoiceCustomer[]>(
  'batch_invoice_customers_reducers/fetch_batch_invoice_customers_success'
)
export const FETCH_BATCH_INVOICE_CUSTOMERS_FAIL = createAction(
  'batch_invoice_customers_reducers/fetch_batch_invoice_customers_fail'
)

interface CreateBatchInvoiceCustomerPayload {
  street: string
  company_name: string
  street_number: string
  zip_code: string
  city: string
}
export type CreateBatchInvoiceCustomerActionType = PayloadAction<CreateBatchInvoiceCustomerPayload>
export const CREATE_BATCH_INVOICE_CUSTOMER = createAction<CreateBatchInvoiceCustomerPayload>(
  'batch_invoice_customers_reducer/create_batch_invoice_customer'
)
export const CREATE_BATCH_INVOICE_CUSTOMER_SUCCESS = createAction(
  'batch_invoice_customers_reducer/create_batch_invoice_customer_success'
)
export const CREATE_BATCH_INVOICE_CUSTOMER_FAIL = createAction(
  'batch_invoice_customers_reducer/create_batch_invoice_customer_fail'
)

export const batchInvoiceCustomersReducer = createReducer(initialState, (builder) => {
  builder.addCase(FETCH_BATCH_INVOICE_CUSTOMERS_SUCCESS, (state, action) => {
    return { ...state, batch_invoice_customers: action.payload }
  })
})

export default batchInvoiceCustomersReducer
