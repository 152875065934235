/* eslint-disable @typescript-eslint/ban-types */
import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit'
import { PersonalizedEmailTemplateType } from 'src/types'

export interface EmailModalReducer {
  isModalOpen: boolean
  modalType: PersonalizedEmailTemplateType
  orderId: number
}

const initialState: EmailModalReducer = {
  isModalOpen: false,
  modalType: '',
  orderId: 0
}

interface OpenModalPayload {
  modalType: PersonalizedEmailTemplateType
  orderId: number
}
export const OPEN_MODAL = createAction<OpenModalPayload>('email_modal_reducer/open_modal')
export const CLOSE_MODAL = createAction('email_modal_reducer/close_modal')

export interface SendEmailPayload {
  to: string
  title: string
  body: string
  orderId: number
  name: PersonalizedEmailTemplateType
}
export type SendEmailActionType = PayloadAction<SendEmailPayload>
export const SEND_EMAIL = createAction<SendEmailPayload>('email_modal_reducer/send_email')
export const SEND_EMAIL_SUCCESS = createAction('email_modal_reducer/send_email_success')
export const SEND_EMAIL_FAILURE = createAction('email_modal_reducer/send_email_failure')

export const emailModalReducer = createReducer(initialState, (builder) => {
  builder.addCase(OPEN_MODAL, (state, action) => {
    return { ...state, modalType: action.payload.modalType, isModalOpen: true, orderId: action.payload.orderId }
  })
  builder.addCase(CLOSE_MODAL, (state, action) => {
    return { ...state, modalType: '', isModalOpen: false }
  })
})
export default emailModalReducer
