import { call, put, select, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { GET } from 'src/consts/methods'
import { FETCH_INVOICES, FETCH_INVOICES_FAIL, FETCH_INVOICES_SUCCESS } from 'src/redux/invoicesReducer'
import { RootReducer } from 'src/redux/rootReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* FetchInvoicesFlow(): Generator<any, void, any> {
  try {
    const currentPage = yield select((state: RootReducer) => state.invoicesReducer.currentPage)
    const sort = yield select((state: RootReducer) => state.invoicesReducer.sort)
    const search = yield select((state: RootReducer) => state.invoicesReducer.search)
    const response = yield call(apiCall, {
      endpoint: '/admin/invoices',
      method: GET,
      params: { page: currentPage, sort, search }
    })
    yield put(FETCH_INVOICES_SUCCESS(response))
  } catch (error) {
    yield put(FETCH_INVOICES_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* FetchInvoicesSaga(): Generator<any, void, any> {
  yield takeLatest(FETCH_INVOICES, FetchInvoicesFlow)
}
