import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { POST } from 'src/consts/methods'
import {
  CREATE_INVOICE_FROM_INVOICE_POSITIONS,
  CREATE_INVOICE_FROM_INVOICE_POSITIONS_FAIL,
  CREATE_INVOICE_FROM_INVOICE_POSITIONS_SUCCESS,
  CreateInvoiceFromInvoicePositionActionType
} from 'src/redux/batchInvoiceCustomerReducer'
import { Invoice } from 'src/types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* CreateInvoiceFromInvoicePositionsFlow({
  payload
}: CreateInvoiceFromInvoicePositionActionType): Generator<any, void, any> {
  try {
    const invoice: Invoice = yield call(apiCall, {
      endpoint: `/admin/batch_invoice_customers/${payload.batchInvoiceCustomerId}/multi_invoices`,
      method: POST,
      params: { multi_invoice: { invoice_positions_ids: payload.invoicePositionIds } }
    })
    yield put(CREATE_INVOICE_FROM_INVOICE_POSITIONS_SUCCESS())
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore
    window.location = `/invoices/${invoice.id}`
  } catch (error) {
    yield put(CREATE_INVOICE_FROM_INVOICE_POSITIONS_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* CreateInvoiceFromInvoicePositionsSaga(): Generator<any, void, any> {
  yield takeLatest(CREATE_INVOICE_FROM_INVOICE_POSITIONS, CreateInvoiceFromInvoicePositionsFlow)
}
