import React, { useEffect, useMemo } from 'react'
import Layout from '../Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducer } from 'src/redux/rootReducer'
import { useLocation, useNavigate } from 'react-router-dom'
import { FETCH_INVOICES, SET_PAGE, SET_SEARCH, SET_SORT, SortType } from 'src/redux/invoicesReducer'
import Pagination from '../molecules/Pagination'
import InvoicesReportsSection from '../molecules/InvoicesReportsSection'

const InvoicesScreen = (): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const invoices = useSelector((state: RootReducer) => state.invoicesReducer.invoices)
  const location = useLocation()
  const currentPage = useSelector((state: RootReducer) => state.invoicesReducer.currentPage)
  const sort = useSelector((state: RootReducer) => state.invoicesReducer.sort)
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  useEffect(() => {
    const page = searchParams.get('page') ?? '1'
    const [sortColumn, sortDirection] = (searchParams.get('sort') ?? 'invoice_number=desc').split('=')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore
    const setSortPayload: SortType = { [sortColumn]: sortDirection }
    dispatch(SET_SORT(setSortPayload))
    const orderNumber = searchParams.get('orderNumber')
    const invoiceNumber = searchParams.get('invoiceNumber')
    const dateMin = searchParams.get('dateMin')
    const dateMax = searchParams.get('dateMax')
    dispatch(
      SET_SEARCH({
        order_number: orderNumber ?? '',
        invoice_number: invoiceNumber ?? '',
        date_min: dateMin ?? '',
        date_max: dateMax ?? ''
      })
    )
    dispatch(SET_PAGE({ page: parseInt(page, 10) }))
  }, [location.search, dispatch, searchParams])

  useEffect(() => {
    dispatch(FETCH_INVOICES())
  }, [dispatch, currentPage, sort])

  const handleSortChange = (colName: 'invoice_number' | 'zip_form' | 'zip_to' | 'created_at'): void => {
    // eslint-disable-next-line spaced-comment, @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error
    //@ts-ignore
    const isCurrentDirAsc = sort[colName] === 'asc'
    searchParams.set('sort', `${colName}=${isCurrentDirAsc ? 'desc' : 'asc'}`)
    searchParams.set('page', '1')
    navigate(`?${searchParams.toString()}`)
  }

  return (
    <Layout withFilter>
      <div className="p-3">
        <h1 className="text-24 ml-6">Rechnungen</h1>
        <div className="h-3" />
        <InvoicesReportsSection />
        <div className="h-3" />
        <div className="bg-white rounded-sm w-full overflow-hidden">
          <div className="border-b-4 border-orange" />
          <div className="p-3">
            <table className="w-full text-14">
              <tr>
                <td
                  onClick={() => {
                    handleSortChange('created_at')
                  }}
                  className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                  Rechnungsnummer
                </td>
                <td
                  onClick={() => {
                    handleSortChange('invoice_number')
                  }}
                  className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                  Vorname und Nachname
                </td>
                <td
                  onClick={() => {
                    handleSortChange('invoice_number')
                  }}
                  className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                  Hausnummer
                </td>
                <td
                  onClick={() => {
                    handleSortChange('zip_form')
                  }}
                  className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                  PLZ (von)
                </td>
                <td
                  onClick={() => {
                    handleSortChange('zip_to')
                  }}
                  className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                  PLZ (nach)
                </td>
                <td
                  onClick={() => {
                    handleSortChange('created_at')
                  }}
                  className="p-2 border border-[#f4f4f4] text-16 font-semibold text-blue cursor-pointer">
                  Erstellungsdatum
                </td>
              </tr>
              {invoices.map(
                ({ id, invoice_number, display_name, street_number, zip_from, zip_to, created_at }, idx) => (
                  <tr
                    onClick={() => {
                      navigate(`/invoices/${id}`)
                    }}
                    key={id}
                    className={`${idx % 2 === 0 ? 'bg-grayBackground' : 'bg-white'} cursor-pointer`}>
                    <td className="p-2 border border-[#f4f4f4]">{invoice_number}</td>
                    <td className="p-2 border border-[#f4f4f4]">{display_name}</td>
                    <td className="p-2 border border-[#f4f4f4]">{street_number}</td>
                    <td className="p-2 border border-[#f4f4f4]">{zip_from}</td>
                    <td className="p-2 border border-[#f4f4f4]">{zip_to}</td>
                    <td className="p-2 border border-[#f4f4f4]">{created_at}</td>
                  </tr>
                )
              )}
            </table>
            <Pagination />
          </div>
        </div>
        <div className="h-4" />
      </div>
    </Layout>
  )
}

export default InvoicesScreen
