import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { GET } from 'src/consts/methods'
import {
  FETCH_INVOICE_POSITIONS,
  FETCH_INVOICE_POSITIONS_FAIL,
  FETCH_INVOICE_POSITIONS_SUCCESS,
  FetchInvoicePositionsActionType
} from 'src/redux/batchInvoiceCustomerReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* FetchInvoicePositionsFlow({ payload }: FetchInvoicePositionsActionType): Generator<any, void, any> {
  try {
    const response = yield call(apiCall, {
      endpoint: `/admin/batch_invoice_customers/${payload.customerId}/invoice_positions`,
      method: GET,
      params: {}
    })
    yield put(FETCH_INVOICE_POSITIONS_SUCCESS(response))
  } catch (error) {
    yield put(FETCH_INVOICE_POSITIONS_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* FetchInvoicePositionsSaga(): Generator<any, void, any> {
  yield takeLatest(FETCH_INVOICE_POSITIONS, FetchInvoicePositionsFlow)
}
