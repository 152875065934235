import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { GENERATE_CSV_REPORT, GENERATE_PDF_REPORT } from 'src/redux/invoicesReducer'

const InvoicesReportsSection = (): JSX.Element => {
  const dispatch = useDispatch()
  const dateNow = new Date()
  const initDateValue = `${dateNow.getFullYear()}-${(dateNow.getMonth() + 1).toString().padStart(2, '0')}-${dateNow
    .getDate()
    .toString()
    .padStart(2, '0')}`
  const [reportFrom, setReportFrom] = useState(initDateValue)
  const [reportTo, setReportTo] = useState(initDateValue)
  return (
    <>
      <div className="flex">
        <input
          value={reportFrom}
          onChange={(e) => {
            setReportFrom(e.target.value)
          }}
          type="date"
          className="px-4 py-2 w-[13.7rem]"
        />
        <div className="w-2" />
        <input
          value={reportTo}
          onChange={(e) => {
            setReportTo(e.target.value)
          }}
          type="date"
          className="px-4 py-2 w-[13.7rem]"
        />
      </div>
      <div>
        <div className="h-5" />
        <button
          onClick={() => {
            dispatch(GENERATE_CSV_REPORT({ report_form: reportFrom, report_to: reportTo }))
          }}
          className="bg-blue text-white px-4 py-3 text-16 rounded-sm font-semibold">
          Zu csv
        </button>
        <div className="h-3" />
        <button
          onClick={() => {
            dispatch(GENERATE_PDF_REPORT({ report_form: reportFrom, report_to: reportTo }))
          }}
          className="bg-blue text-white px-4 py-3 text-16 rounded-sm ont-semibold">
          Zu Pdf
        </button>
        <div className="h-5" />
      </div>
    </>
  )
}

export default InvoicesReportsSection
