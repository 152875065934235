import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { GET } from 'src/consts/methods'
import {
  FETCH_INVOICE,
  FETCH_INVOICE_FAIL,
  FETCH_INVOICE_SUCCESS,
  FetchInvoiceActionType
} from 'src/redux/invoiceReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* FetchInvoiceFlow(action: FetchInvoiceActionType): Generator<any, void, any> {
  try {
    const response = yield call(apiCall, {
      endpoint: `/admin/invoices/${action.payload.id}`,
      method: GET,
      params: {}
    })
    yield put(FETCH_INVOICE_SUCCESS(response))
  } catch (error) {
    yield put(FETCH_INVOICE_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* FetchInvoiceSaga(): Generator<any, void, any> {
  yield takeLatest(FETCH_INVOICE, FetchInvoiceFlow)
}
