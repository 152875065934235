import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { SIGN_IN } from 'src/redux/authReducer'

const SignIn = (): JSX.Element => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  return (
    <div className="relative">
      <img src="/admin-public/assets/images/login-background.jpg" className="w-screen h-screen" />
      <div className="absolute w-screen h-screen top-0 right-0 flex items-center justify-center z-20">
        <div className="bg-darkGrey flex-1 max-w-[25rem] p-6">
          <input
            value={email}
            placeholder="Email"
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            className="w-full h-8 rounded-sm px-2"
          />
          <div className="h-2" />
          <input
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            value={password}
            placeholder="Password"
            type="password"
            className="w-full h-8 rounded-sm px-2"
          />
          <div className="h-4" />
          <button
            onClick={() => dispatch(SIGN_IN({ email, password }))}
            className="bg-orange py-2 text-center rounded-md cursor-pointer w-full">
            Login in
          </button>
        </div>
      </div>
    </div>
  )
}

export default SignIn
